/* eslint-disable no-restricted-globals */
import React from 'react';
import { Helmet } from 'react-helmet';
import ScrollTrigger from 'react-scroll-trigger';
import { graphql } from 'gatsby';
import { Layout } from 'antd';

import IndexBanner from '../components/IndexBanner';
import GlobalStyle from '../configs/globalStyle';
import IndexForVets from '../components/IndexForVets';
import SEO from '../components/seo';
import Header from '../components/Header';
import IndexSubForVets from '../components/IndexForVets';
import IndexContact from '../components/IndexContact';

interface HomeProps {
  data: any;
}

const Home: FC<HomeProps> = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <SEO />
      <Header />
      <Layout.Content>
        <GlobalStyle />
        <ScrollTrigger
          onEnter={() => {
            location.hash = '#banner';
          }}
        >
          <IndexBanner backgroundImage={data.background} />
        </ScrollTrigger>
        <ScrollTrigger
          onEnter={() => {
            location.hash = '#forVets';
          }}
        >
          <IndexForVets
            items={[
              {
                title: 'Bookings',
                subTitle: 'Book consultations with vets that fit your needs ',
                description: `Select your preferred vet, or for urgent issues choose the next-available vet. Booking is both easy and thorough with options to upload relevant descriptions and photos.`,
                btnText: '',
                btnUrl: '#',
                image: data.service1,
              },
              {
                title: 'Consultations',
                subTitle: 'Communicate with experienced vets digitally',
                description: `Share information with vets seamlessly through text and voice messages, calls, and video chats. We strive to make communication cost-effective and hassle-free for farmers and pet owners.`,
                btnText: '',
                btnUrl: '',
                image: data.service2,
              },
            ]}
          />
          <IndexSubForVets />
        </ScrollTrigger>
        <ScrollTrigger
          onEnter={() => {
            location.hash = '#contact';
          }}
        >
          <IndexContact
            backgroundImage={data.backgroundContact}
            backgroundImageResponsive={data.backgroundContact2}
          />
        </ScrollTrigger>
      </Layout.Content>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query {
    background: file(relativePath: { eq: "img_banner_background.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    service1: file(relativePath: { eq: "ip-12-forvets-1.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    service2: file(relativePath: { eq: "ip-12-forvets-2.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    backgroundContact: file(
      relativePath: { eq: "PAV_background_contact.png" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    backgroundContact2: file(
      relativePath: { eq: "PAV_backround_contact_2.png" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
